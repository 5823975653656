<template>
  <div>
    <el-form :model="form"
             ref="formRef">
      <el-row :gutter="10">
        <el-col :lg="5"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-form-item>
            <el-input clearable
                      style="width:100%"
                      @keyup.enter.native="search"
                      v-model="form.user_id"
                      placeholder="用户ID或手机或姓名" />
          </el-form-item>
        </el-col>
        <el-col :lg="5"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-form-item>
            <el-input clearable
                      style="width:100%"
                      @keyup.enter.native="search"
                      v-model="form.search_paper_id"
                      placeholder="试卷ID" />
          </el-form-item>
        </el-col>
        <el-col :lg="5"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-form-item>
            <el-select v-model="form.subject_id"
                       style="width:100%"
                       clearable
                       @change="search"
                       placeholder="选择科目">
              <el-option v-for="item in suubjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="3"
                :md="6"
                :sm="12"
                :xl="4"
                :xs="8">
          <el-form-item label="只显示未改">
            <!-- 显示传on，不显示传空 -->
            <el-radio v-model="form.ShowOnlyCanBeChecked"
                      @click.native.prevent="changeChecked('ShowOnlyCanBeChecked')"
                      label="on"></el-radio>
          </el-form-item>
        </el-col>
        <el-col :lg="3"
                :md="6"
                :sm="4"
                :xl="4"
                :xs="4">
          <el-form-item label="显示测试账号">
            <el-radio v-model="form.ShowTestUser"
                      @click.native.prevent="changeChecked('ShowTestUser')"
                      label="on"></el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary"
                     @click="search">提交</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table border
              highlight-current-row
              :stripe="true"
              :max-height="$store.state.tableHeight + 50 "
              v-loading="tableLoading"
              :data="tableData"
              style="width: 100%">
      <el-table-column prop="user_paper_id"
                       align="center"
                       width="100"
                       v-if="tableColumns[0].isShow "
                       label="试卷ID" />
      <el-table-column prop="subject_name"
                       align="center"
                       width="120"
                       v-if="tableColumns[1].isShow "
                       label="科目名称" />
      <el-table-column prop="module_name"
                       align="center"
                       v-if="tableColumns[2].isShow "
                       label="模块名称" />
      <div v-if="info.isEduAdmin && userInfo.is_parttime == 0">
        <el-table-column prop="mobile"
                         align="center"
                         v-if="tableColumns[4].isShow "
                         label="用户手机号" />
        <el-table-column prop="truename"
                         align="center"
                         width="120"
                         v-if="tableColumns[5].isShow "
                         label="用户姓名" />
      </div>
      <el-table-column prop="edu_school_name"
                       align="center"
                       v-if="tableColumns[6].isShow "
                       label="所在学校" />
      <el-table-column prop="paper_time"
                       align="center"
                       v-if="tableColumns[7].isShow "
                       sortable
                       width="160"
                       label="试卷时间" />
      <el-table-column prop="name"
                       align="center"
                       v-if="tableColumns[8].isShow "
                       label="是否批改">
        <template slot-scope="{row}">
          <div class="opertion_font">
            <span style="color:#FE4C4C"
                  v-if="(row.judge_status == 1 || row.judge_status == 2) && row.other_answer == ''">
              答题卡未提交
            </span>
            <span class="pedding"
                  v-else-if="row.judge_status == 1 || row.judge_status == 2">
              未批改
            </span>
            <span class="pedding"
                  v-else-if="row.judge_status == 4">
              已全部批改
            </span>

          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name"
                       align="center"
                       fixed="right"
                       v-if="tableColumns[9].isShow "
                       label="操作">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             @click="showController"></i>
          操作
        </template>
        <template slot-scope="{row}">
          <div class="opertion_font">
            <span style="color:#FE4C4C"
                  v-if="(row.judge_status == 1 || row.judge_status == 2) && row.other_answer == ''">
              无法批改
            </span>
            <div class="text_btn_primary"
                 v-else-if="row.judge_status == 1 || row.judge_status == 2"
                 @click="toPage(row)">
              批改
            </div>
            <div class="text_btn_primary"
                 v-else-if="row.judge_status == 3 && row.assist_id == info.edu_user_id"
                 @click="toPage(row)">
              继续批改
            </div>

            <div v-else-if="row.judge_status == 4">
              批改完成
            </div>
            <div v-else>
              批改中
            </div>
            <!-- row.judge_status != 0 &&row.judge_status != 2 && row.judge_status !=4 && -->
            <!-- {{![0,2,4].includes(row.judge_status) && row.assist_id != userInfo.edu_user_id && userInfo.is_parttime == 0}} -->
            <div class="text_btn_primary"
                 v-if="![0,1,2,4].includes(row.judge_status) && row.assist_id != userInfo.edu_user_id && userInfo.is_parttime == 0"
                 @click="editStatus(row)">
              修改状态为未改
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Shuttle ref="ShuttleRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import property from './property'
import opertion from './opertion'
export default {
  name: 'checkTest',
  mixins: [property, opertion]
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio__label {
  display: none;
}
.pedding {
  color: green;
}
</style>